body {
  background: #141413;
  color: #ebedc7;
  font-family: Verdana;
}

/* Component Features */

.intro {
  display: block;
  float: left;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.searching {
  display: block;
  float: left;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

input {
  width: 400px;
  height: 35px;
  border: 3px solid #ebedc7;
  border-radius: 3px;
  font-size: 17px;
}

button {
  width: 180px;
  height: 60px;
  font-size: 25px;
  border: 6px solid #ebedc7;
  border-radius: 8px;
  background: black;
  color: #ebedc7;
  margin: 1em;
}

button:hover {
  background-color: #ebedc7;
  color: black;
}

.buttoncontainer {
  display: inline-block;
  float: left;
  width: 50%;
  text-align: center;
  margin: 10px auto;
}

#playbutton {
  width: 300px;
  height: 150px;
  font-size: 50px;
  border-radius: 20px;
  border-width: 10px;
}

.namegame {
  color: #f9f8b1;
  display: block;
  float: left;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.namegame img {
  max-width: 400px;
}

.results {
  display: block;
  color: #f9f1d9;
  margin: 30px;
}

.col {
  display: block;
  margin: 5px auto;
  float: left;
  width: 50%;
}

.titlegame {
  width: 100%;
  display: block;
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 30px;
}

.playagainbutton {
  display: block;
  width: 100%;
  text-align: center;
  margin: 10px 0 80px 0;
}

.noresult {
  display: block;
  width: 100%;
  text-align: center;
}


/* Cards */

.cardborder {
  display: block;
  height: 350px;
  background: #f9f9cf;
  box-shadow: 6px 6px #3a3a38;
  margin: 30px auto;
  width: 60%;
  padding: 20px;
  overflow-y: auto;
}

.title {
  display: block;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: auto;
  color: #f9f9cf;
  background-color: #4A5050;
  border: 2px solid #4A5050;
  border-radius: 10px;
  padding: 12px;
  text-align: center;
  width: 65%
}

.image {
  position: relative;
  display: block;
  margin: auto;
  overflow: hidden;
}

.image img {
  width: 60%;
  display: block;
  margin: auto;
}

.leftbox {
  display: block;
  position: relative;
  width: 60%;
  float: left;
}

.rightbox {
  display: block;
  position: relative;
  width: 40%;
  float: left;
  margin: auto;
}

.bottombox {
  display: block;
  float: left;
  margin: 15px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.desc {
  color: #4A5050;
  display: block;
  width: 75%;
  margin: auto;
}

.creator {
  text-align: center;
  color: #4A5050;
  font-weight: bold;
  font-size: 15px;
  margin: 20px;
}

/* Header */

.placeholder {
  height: 300px;
}
.navbar {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(#fcfc9c, #ebedc7);
  text-align: center;
  font-weight: bold;
  z-index: 1;
  padding: 30px;
}

.navtitle {
  display: block;
  font-size: 40px;
  float: left;
  width: 100%;
  color: #4A5050;
  text-align: left;
  padding-left: 40px;
  text-decoration: none;
}

.emily {
  display: block;
  font-size: 20px;
  float: left;
  text-align: left;
  width: 100%;
  color: #4A5050;
  margin-top: 30px;
  padding-left: 40px;
}

.navbar a {
  text-decoration: none;
  color: #4A5050
}

/* Search */

input {
  display: block;
  margin: 10px auto 10px auto;
  width: 300px;
  height: 30px;
}

.searchtext {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 25px
}

.searchcontent {
  width: 100%;
  display: block;
  margin: auto;
  text-align: center;
}

.searchstuff {
  text-align: center;
}


@media all and (max-width: 950px) {

  .navbar {
    padding: 30px 0px;
  }

  .navtitle {
    font-size: 25px;
    float: none;
    width: 100%;
    margin: 0;
  }

  .emily {
    font-size: 20px;
    float: none;
    width: 100%;
    margin: 0;
  }

  .searchtext {
    font-size: 20px;
  }

  .cardborder {
    background: #f9f9cf;
    box-shadow: 6px 6px #3a3a38;
    height: 300px;
  }

  .title {
    font-size: 1em;

  }

  .image img {
    width: 70%;
  }

  .leftbox{
    width: 60%;
  }

  .rightbox {
    width: 40%;
  }

  .bottombox {
    display: block;
    float: left;
    margin: 12px;
  }
}

@media all and (max-width: 600px) {
  .cardborder {
    height: 150px
  }

  .title {
    font-size: .6em;
    margin: 10px;
  }

  .creator {
    font-size: 10px;
  }
}
